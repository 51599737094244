<template>
  <div>
    <h3 ref="intro">
      Definition of Arithmetic Mean
    </h3>
    <p>
      In mathematics, the arithmetic mean of a set of values or data is defined as the sum total of
      all the values in the set divided by the number of values in the set.<br><br>
      For example, consider the following set of values:
      $$x =\{2, 4, 6, 8, 10\}$$
      The arithmetic mean for this set of values is then given by:
      $$ \bar{x} = \frac{2+4+6+8+10}{5} = 6$$
      </body>
    </p>
    <h3 ref="types">
      Significance of Arithmetic Mean
    </h3>
    <p>
      The arithmetic mean of a set of values is that one value if each item in the set
      can be assigned this same value while getting the same sum total.
      <br><br>For instance, in the above example, we can assign each entry in the set a value of 6 and we will still end up with
      the same sum total (i.e., 36).
    </p>
    <br>
    <h3 ref="pg">
      MagicGraph | Understanding Arithmetic Mean
    </h3>
    <p>
      You are given three weighing scales: a red scale, a blue scalel and a green scale.
      Each scale is stacked with certain number of weights.<br><br>
      The goal of this MagicGraph is to find the arithmetic mean of the three weights.
      To find the arithmetic mean:
      <br><br>
      Move the weights around between the three scales such that:<br><br>
      <ul class="a">
        <li>Each scale shows the same reading, and <br> </li>
        <li>Sum total of the weights on three scale remains unchanged.</li>
      </ul>
      <br>
      Your can add or remove bricks by tapping on the empty squares stacked over each scale.
      You can test your answer by tapping on the 'test your answer' button. <br><br>
      Tap on the shuffle button to genrate a new configuration.
    </p>
    <br>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'ArithmeticMean',
  created: function () {
    this.$store.commit('navigation/resetState');
    let title = 'Arithmetic Mean ';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'Geometric Tools', img:'/assets/number-1.svg', action: () => this.goto('intro')},
      {title: 'Common Geometric Tools', img:'/assets/number-2.svg', action: () => this.goto('types')},
      {title: 'MagicGraph',img:'/assets/touch.svg', action: () => this.goto('pg')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newMath =true;
    this.$store.commit('navigation/replaceMath', newMath);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo() {
  return{ title: 'Arithmetic Mean',
          titleTemplate: '%s - Learn interactively',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {name: 'description', content: 'Learn interactively about Arithmetic Mean'}
                ]
        }
   },
}
</script>

<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
@import 'src/styles/screen-sizes.scss';
.icn{
color: var(--v-red-base);;
}
</style>
