import {
    makeResponsive,
    placeTitle,
    placeImage,
    placeInput,
    placeSlider,
    hoverMe,
    placeRec,
    hiddenPt,
    fixedPt,
    clearInputFields,
    dragMe,
    placeArrow,
    placeGravity,
    placeText,
    placeMiddleText,
    placeRightText,
    placeLine,
    placePoint,
    placeGlider,
    placeRuler,
    placeLeftText,
    placeCircle,
    placeAngle,
    placeDash,
    placeLabel,
    placeArc,
    placeLogo,
    placeShuffle,
    placeErase,
    placeTest,
    placeWhite,
    placeBCircles,
    placeCircles,
    createSpace,
    placePlay,
    placeChecks,
    placeCross,
    placeExclaim,
    writeHTMLText,
    placeStartOver
} from '../Utils';
const Boxes = {
    //JXG.Options.line.highlight=false;
    box1: function () {
    //Create the Board
    /*var brd1 = JXG.JSXbrd1.initBoard('jxgbox1',{boundingbox: [-5, 18, 20, -7],
        keepaspectratio: true, axis:false, ticks:{visible:true},
        grid:true, showCopyright:false, showNavigation:false,
        pan:{enabled:false}, zoom:{enabled:false}});*/
    var brd1 = createSpace(-5, 10, -7, 8);
    brd1.suspendUpdate();
    // Layering
    brd1.options.axis.strokeWidth=0;
    brd1.options.layer['image']=16;
    brd1.options.layer['text']=16;
    brd1.options.layer['line']=16;
    brd1.options.layer['point']=17;
    brd1.options.layer['glider']=17;
    brd1.options.layer['angle']=18;
    brd1.options.board.minimizeReflow = 'none';
    brd1.options.point.showInfobox =false;
    brd1.options.line.highlight=false;
    brd1.options.image.highlight=false;
    brd1.options.text.highlight=false;
//    brd1.options.text.display='internal';
    //Make the board responsive
    makeResponsive(brd1);
    // Placing Logo
    //Placing Title
    placeTitle(brd1, 'Arithmetic Mean', '(Find the mean of weights on the three scales)');
    /*********************brd1 DIMENSIONS*******************/
    const boundingBox = brd1.attr.boundingbox;
    const positionX = (boundingBox[2]+boundingBox[0])/2;
    const positionY = (boundingBox[1]+boundingBox[3])/2;
    const height = (boundingBox[1]-boundingBox[3])/2;
    /********************************************************/
    var yourScore =0; var yourMissed =0; var yourWrong =0; var yourTotal=0;
    var scoreCard = writeHTMLText(brd1, positionX, positionY+1, function(){return 'Your Score ( &check; ): '+ yourScore+'/5'});
    var missedCard = writeHTMLText(brd1, positionX, positionY, function(){return 'Missed Questions ( ! ): '+ yourMissed+'/5'});
    var wrongCard = writeHTMLText(brd1, positionX, positionY-1, function(){return 'Wrong Answers ( x ): '+ yourWrong+'/5'});
    scoreCard.setAttribute({visible:false});
    missedCard.setAttribute({visible:false});
    wrongCard.setAttribute({visible:false});
///////////////////////////GRAPHICS MODULES//////////////////
    var show =placeBCircles(brd1);
    var hide=placeCircles(brd1);
    var check = placeChecks(brd1);
    var cross = placeCross(brd1);
    var exclaim = placeExclaim(brd1);
    hide[0].setAttribute({visible:false});
//////////////////////////////////////////////
    // Placing the Eraser Button
//////////////////////////////////////////////////////////////////////////////////////////////////////
    //FIRST
    const list1 = [2, 1, 1, 2, 5];
    const list2 = [1, 4, 6, 3, 4,];
    const list3 = [0, 1, 2, 7, 6];
    var i = 0;
///////////////////////////weight watcher//////////////////////////
    placeRightText(brd1, 3, -4.5, 'Total of Three Weights');
    placeMiddleText(brd1, 3.5, -4.5, function(){if(mass1+mass2+mass3<list1[i]+list2[i]+list3[i]){return '<'}else if(mass1+mass2+mass3>list1[i]+list2[i]+list3[i]){return '>'}else{return '='}});
    placeLeftText(brd1, 4, -4.5, ()=>list1[i]+list2[i]+list3[i]);
////////////////////////////////////////////////////////////////////////
    var mass1=2, mass2=1, mass3=0, num1=2, num2=1, num3=0;
    var width=1;
    var num_height=6;
    var num_width=2;
    var rectLeft=[];
    var rectRight=[];
    var rectMiddle=[];
    /////////////////////////
    var crectLeft=[];
    var crectRight=[];
    var crectMiddle=[];
    /////////////////////////////////////
    var rectLeftVisibility=[];
    var rectRightVisibility=[];
    var rectMiddleVisibility=[];
    /////////////////////////////////////////
    var crectLeftVisibility=[];
    var crectRightVisibility=[];
    var crectMiddleVisibility=[];
    ///////////////////////////////////////////
    var textLeft=[];
    var textRight=[];
    var textMiddle=[];
    var cmx=0,cmy=0;
   ///////////////////////////////////////////////
    for (let y=0; y<num_height; y++)
        {
            let v1 = true;
            let v2 = false;
            let rectyLeft = brd1.create('image', ['/assets/whiteSquare.svg', [-3*width, (y-1)*width], [1.01,1.01]], {visible:y>=num1, opacity:0.25, fixed:true});
            let rectyRight = brd1.create('image', ['/assets/whiteSquare.svg', [1*width, (y-1)*width], [1.01,1.01]], {visible:y>=num2, opacity:0.25, fixed:true});
            let rectyMiddle = brd1.create('image', ['/assets/whiteSquare.svg', [5*width, (y-1)*width], [1.01,1.01]], {visible:y>=num3, opacity:0.25, fixed:true});

            let crectyLeft = brd1.create('image', ['/assets/orangeSquare.svg', [-3*width, (y-1)*width], [1.01,1.01]], {visible:y<num1, fixed:true});
            let textyLeft = placeMiddleText(brd1, -3*width+0.5*width, (y-1)*width+0.5*width, '1 kg');
            textyLeft.setAttribute({visible:y<num1});

            let crectyRight = brd1.create('image', ['/assets/orangeSquare.svg', [1*width, (y-1)*width], [1.01,1.01]], {visible:y<num2, fixed:true});
            let textyRight = placeMiddleText(brd1, 1*width+0.5*width, (y-1)*width+0.5*width, '1 kg');
            textyRight.setAttribute({visible:y<num2});
////////////////////////////////////////////
            let crectyMiddle = brd1.create('image', ['/assets/orangeSquare.svg', [5*width, (y-1)*width], [1.01,1.01]], {visible:y<num3, fixed:true});
            let textyMiddle = placeMiddleText(brd1, 5*width+0.5*width, (y-1)*width+0.5*width, '1 kg');
            textyMiddle.setAttribute({visible:y<num3});
////////////////////////////////////////////////////////////////////////
            rectLeftVisibility.push(y>=num1);
            crectLeftVisibility.push(y<num1);
            rectRightVisibility.push(y>=num2);
            crectRightVisibility.push(y<num2);
            rectMiddleVisibility.push(y>=num3);
            crectMiddleVisibility.push(y<num3);
///////////////////////////////////////////////
            rectyLeft.on('up', function(){changeLeftRectColorxy(y); });
            crectyLeft.on('up', function(){changeLeftRectColorxy(y); });
            rectyRight.on('up', function(){changeRightRectColorxy(y); });
            crectyRight.on('up', function(){changeRightRectColorxy(y); });
            rectyMiddle.on('up', function(){changeMiddleRectColorxy(y); });
            crectyMiddle.on('up', function(){changeMiddleRectColorxy(y); });
            ////////////////////////////////////
            rectLeft.push(rectyLeft);
            rectRight.push(rectyRight);
            rectMiddle.push(rectyMiddle);
            /////////////////////////////
            crectLeft.push(crectyLeft);
            crectRight.push(crectyRight);
            crectMiddle.push(crectyMiddle);
            //////////////////////////////////
            textLeft.push(textyLeft);
            textRight.push(textyRight);
            textMiddle.push(textyMiddle);
            ///////////////////////////////////////
        }
///////////////////////////////////////////////////////////
            var massLeft=1;
            var massMiddle=1;
            var massRight =1;
////////////////////////////////////////////////////////////////
//brd1.create('image', ['/assets/triangle.svg', [7, 12], [1.0, 1.0]], {visible:true, fixed:true, opacity:1});
////////////////////////////////////////////////////////////////////////////////////
    function changeLeftRectColorxy(y)
    {

        let current_visibility1 = rectLeftVisibility[y];
        let current_visibility2 = crectLeftVisibility[y];
        console.log(current_visibility2, current_visibility1);

        if (current_visibility1==true && current_visibility2==false)
        {
            current_visibility1=false;
            current_visibility2=true;

        }
        else{
            current_visibility1=true;
            current_visibility2=false;
        }
        rectLeft[y].setAttribute({visible: current_visibility1});
        crectLeft[y].setAttribute({visible: current_visibility2});
        textLeft[y].setAttribute({visible: current_visibility2});
        rectLeftVisibility[y]=current_visibility1;
        crectLeftVisibility[y]=current_visibility2;
        mass1 =0;
        for (let i=0;i<num_height;i++)
             {
                 if (crectLeftVisibility[i]==true)
                 {
                    mass1+=massLeft;
                //    num1+=1;
                 }
             }
     }

     function changeRightRectColorxy(y)
     {

         let current_visibility1 = rectRightVisibility[y];
         let current_visibility2 = crectRightVisibility[y];

         if (current_visibility1==true && current_visibility2==false)
         {
             current_visibility1=false;
             current_visibility2=true;

         }
         else{
             current_visibility1=true;
             current_visibility2=false;
         }

         rectRight[y].setAttribute({visible: current_visibility1});
         crectRight[y].setAttribute({visible: current_visibility2});
         textRight[y].setAttribute({visible: current_visibility2});
         rectRightVisibility[y]=current_visibility1;
         crectRightVisibility[y]=current_visibility2;
         mass2=0;
         for (let i=0;i<num_height;i++)
              {
                  if (crectRightVisibility[i]==true)
                  {
                     mass2+=massRight;
                  //   num2+=1;
                  }
              }
      }
      function changeMiddleRectColorxy(y)
      {

          let current_visibility1 = rectMiddleVisibility[y];
          let current_visibility2 = crectMiddleVisibility[y];

          if (current_visibility1==true && current_visibility2==false)
          {
              current_visibility1=false;
              current_visibility2=true;

          }
          else{
              current_visibility1=true;
              current_visibility2=false;
          }

          rectMiddle[y].setAttribute({visible: current_visibility1});
          crectMiddle[y].setAttribute({visible: current_visibility2});
          textMiddle[y].setAttribute({visible: current_visibility2});
          rectMiddleVisibility[y]=current_visibility1;
          crectMiddleVisibility[y]=current_visibility2;
          mass3=0;
          for (let i=0;i<num_height;i++)
               {
                   if (crectMiddleVisibility[i]==true)
                   {
                      mass3+=massRight;
                  //    num3+=1;
                   }
               }
       }
    brd1.create('image', ['/assets/red-scale.svg', [-4, -3.83], [3.0, 3.0]], {visible:true, fixed:true, opacity:1});
    var txt1=placeMiddleText(brd1, -2.5, -2.65, function(){return '' + (mass1).toFixed(0) + ' kg'});
    txt1.setAttribute({color:'white', display:'internal'});
    //////////////////////////////////////
    brd1.create('image', ['/assets/blue-scale.svg', [0, -3.83], [3.0, 3.0]], {visible:true, fixed:true, opacity:1});
    var txt2=placeMiddleText(brd1, 1.5, -2.65, function(){return '' + (mass2).toFixed(0) + ' kg'});
    txt2.setAttribute({color:'white', display:'internal'});
    ////////////////////////////////////////////
    brd1.create('image', ['/assets/green-scale.svg', [4, -3.83], [3.0, 3.0]], {visible:true, fixed:true, opacity:1});
    var txt3=placeMiddleText(brd1, 5.5, -2.65, function(){return '' + (mass3).toFixed(0) + ' kg'});
    txt3.setAttribute({color:'white', display:'internal'});
///////////////////////////////////////////////////
    var bck =placeWhite(brd1);
    placeLogo(brd1);
    ////////////////////////////
    var analytics =placePlay(brd1, 'right');
    hoverMe(brd1, analytics, 'Tap to Shuffle', 0, 0);
    /////////////////////////////////////////////
    var erase = placeTest(brd1, 'left');
    hoverMe(brd1, erase, 'Next Question', 0, 0);
    /////////////////////////////////////////////
    var redo = placeStartOver(brd1);
    redo.setAttribute({visible:false});
    hoverMe(brd1, redo, 'Start Over', 0, 0);
    ////////////////////////////////////////////////////////////////////
    analytics.on('down', function(){
      if(i<=list1.length-1)
      {
        if(mass1 == mass2 && mass2 == mass3 && yourTotal<list1.length)
        { //is correct
            cross[i].setAttribute({visible:false});
            exclaim[i].setAttribute({visible:false});
            check[i].setAttribute({visible:true});
            yourScore = yourScore+1;
          }
          else if(yourTotal< list1.length)
          { //is incorrect
            exclaim[i].setAttribute({visible:false});
            check[i].setAttribute({visible:false});
            cross[i].setAttribute({visible:true});
            yourWrong = yourWrong+1;
          }
        yourTotal = yourScore + yourWrong + yourMissed;
      }
      if(i==list1.length-1)
      {
      bck.setAttribute({opacity:1});
      scoreCard.setAttribute({visible:true});
      missedCard.setAttribute({visible:true});
      wrongCard.setAttribute({visible:true});
      redo.setAttribute({visible:true});
      //alpha.setAttribute({visible:false});
      //beta.setAttribute({visible:false});
      }
      else{
        scoreCard.setAttribute({visible:false});
        missedCard.setAttribute({visible:false});
        wrongCard.setAttribute({visible:false});
        i=i+1;
      }
      hide[i].setAttribute({visible:false});
      num1 = list1[i];
      num2 = list2[i];
      num3 = list3[i];
      mass1 = num1;
      mass2 = num2;
      mass3 = num3;
      for(let y=0;y<num_height;y++){
        rectLeft[y].setAttribute({visible:y>=num1});
        crectLeft[y].setAttribute({visible:y<num1});
        textLeft[y].setAttribute({visible:y<num1});
        rectRight[y].setAttribute({visible:y>=num2});
        crectRight[y].setAttribute({visible:y<num2});
        textRight[y].setAttribute({visible:y<num2});
        rectMiddle[y].setAttribute({visible:y>=num3});
        crectMiddle[y].setAttribute({visible:y<num3});
        textMiddle[y].setAttribute({visible:y<num3});
        rectLeftVisibility[y] = y>=num1;
        crectLeftVisibility[y] = y<num1;
        rectRightVisibility[y] = y>=num2;
        crectRightVisibility[y] = y<num2;
        rectMiddleVisibility[y] = y>=num3;
        crectMiddleVisibility[y] = y<num3;
      }
    });
//////////////////////////////////////////////////////////////////
    erase.on('down', function(){
      if(i<list1.length)
      {
        if(mass1 == mass2 && mass2 == mass3 && yourTotal<list1.length)
    	  { //is correct
            cross[i].setAttribute({visible:false});
            exclaim[i].setAttribute({visible:false});
            check[i].setAttribute({visible:true});
          }
          else if(yourTotal< list1.length)
          { //is incorrect
            exclaim[i].setAttribute({visible:false});
            check[i].setAttribute({visible:false});
            cross[i].setAttribute({visible:true});
          }
      }
    });
    redo.on('down', function(){
    	//Hiding all blue circles
    	  hide.forEach(function(item){item.setAttribute({visible:true})});
        hide[0].setAttribute({visible:false});
      //Hiding all check marks
        check.forEach(function(item){item.setAttribute({visible:false})});
      //Hiding all cross marks //
        cross.forEach(function(item){item.setAttribute({visible:false})});
      //Hiding all exclaimation marks
    	  exclaim.forEach(function(item){item.setAttribute({visible:false})});
      ///////////////resetting scores//////////////////
    	  yourScore=0;
    	  yourMissed=0;
    	  yourWrong=0;
    	  yourTotal=0;
    	  scoreCard.setAttribute({visible:false});
    	  missedCard.setAttribute({visible:false});
        wrongCard.setAttribute({visible:false});
      /////////////////////////////////////////////
    	  i=0;
        redo.setAttribute({visible:false});
        bck.setAttribute({opacity:0});
        num1 = list1[0];
        num2 = list2[0];
        num3 = list3[0];
        mass1 = num1;
        mass2 = num2;
        mass3 = num3;
      /////////////////////////////////////////////
      for(let y=0;y<num_height;y++){
        rectLeft[y].setAttribute({visible:y>=num1});
        crectLeft[y].setAttribute({visible:y<num1});
        textLeft[y].setAttribute({visible:y<num1});
        rectRight[y].setAttribute({visible:y>=num2});
        crectRight[y].setAttribute({visible:y<num2});
        textRight[y].setAttribute({visible:y<num2});
        rectMiddle[y].setAttribute({visible:y>=num3});
        crectMiddle[y].setAttribute({visible:y<num3});
        textMiddle[y].setAttribute({visible:y<num3});
        rectLeftVisibility[y] = y>=num1;
        crectLeftVisibility[y] = y<num1;
        rectRightVisibility[y] = y>=num2;
        crectRightVisibility[y] = y<num2;
        rectMiddleVisibility[y] = y>=num3;
        crectMiddleVisibility[y] = y<num3;
      }
    });
    brd1.unsuspendUpdate();
                      },
              }
export default Boxes;
